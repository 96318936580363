import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueRouter from 'vue-router';
import { TribeNames } from './enums/tribe-names';

const routes = [
  {
    path: '/',
    redirect: '/' + TribeNames.JAVA
  },
  { 
    path: "/:selectedTribe",
    name: "selectedTribe",
    component: App
  }];

Vue.config.productionTip = false
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes
});
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
