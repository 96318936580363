const sortDataToColumns = (
  dataArray: Array<Array<unknown>>,
  maxColumnLength: number = 20
): Array<Array<unknown>> => {
  const positionedData: Array<Array<unknown>> = [];
  let positionInQuadrant: number = 1;
  let currentColumn: number = 0;

  for (let ring = 0; ring < dataArray.length; ring++) {
    const entries = dataArray[ring];

    for (var i = 0; i < entries.length; i++) {
      const entry = entries[i];
      currentColumn = calculateColumn(
        entries,
        ring,
        i,
        maxColumnLength,
        currentColumn,
        positionedData
      );
      const entriesCurrentColumn = positionedData[currentColumn] || [];
      positionedData[currentColumn] = [...entriesCurrentColumn, entry];
    }
  }

  return positionedData;
};

const calculateColumn = (
  entries: Array<unknown>,
  ring: number,
  positionInRing: number,
  maxColumnLength: number,
  currentColumn: number,
  positionedData: Array<Array<unknown>>
): number => {
  const entriesCurrentColumn = positionedData[currentColumn] || [];
  const itemsLeftCurrentColumn = maxColumnLength - entriesCurrentColumn.length;
  let itemsLeftNextColumn = null;

  if (itemsLeftCurrentColumn <= 0) {
    currentColumn++;
  } else {
    if (positionInRing === 0) {
      const ringCount = entries.length;
      const fitAllCurrentColumn =
        itemsLeftCurrentColumn - ringCount >= 0 ? true : false;
      const fitAllNextColumn = maxColumnLength - ringCount >= 0 ? true : false;

      if (!fitAllCurrentColumn && fitAllNextColumn) {
        currentColumn++;
      } else if (fitAllCurrentColumn) {
      } else {
        const remainder = ringCount % maxColumnLength;

        if (itemsLeftCurrentColumn === maxColumnLength) {
        } else if (itemsLeftCurrentColumn < remainder) {
          currentColumn++;
        } else {
        }
      }
    } else {
    }
  }
  return currentColumn;
};

export { sortDataToColumns };
