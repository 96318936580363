export enum TribeNames {
	ARCHITECTUUR = "Architectuur",
	JAVA = "Java",
	MICROSOFT = "Microsoft",
	FRONT_END = "Front-end",
	AUTOMATED_TESTING = "Automated-testing",
	CLOUD_DEVOPS = "Cloud-devops",
	MOBILE = "Mobile",
	DATA = "Data",
	AI = "AI",
	SECURITY = "Security"
}
