

































































































import { TribeNames } from '@/enums/tribe-names';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class TribeNav extends Vue {

	private tribeNames: typeof TribeNames = TribeNames;

	goToRoute(name:string) {
		this.$router.push({name: "selectedTribe", params: {selectedTribe: name}, query: this.$route.query}).catch(error => {
			if (
				error.name !== 'NavigationDuplicated' &&
				!error.message.includes('Avoided redundant navigation to current location')
			) {
				console.log(error);
			}
		});
	}
}
