











import { Component, Vue } from 'vue-property-decorator';
import Header from './components/Header.vue';
import TechRadar from './components/TechRadar.vue';
import TribeNav from './components/TribeNav.vue';

@Component({
  components: {
    Header,
    TechRadar,
    TribeNav
  },
})
export default class App extends Vue {
}
