






import { Quadrants } from '@/enums/quadrants';
import { Rings } from '@/enums/rings';
import { TribeNames } from '@/enums/tribe-names';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Architectuur from '../tribe-data/Architectuur.json'
import Java from '../tribe-data/Java.json'
import Front_end from '../tribe-data/Front-end.json';
import Mobile from '../tribe-data/Mobile.json';
import Cloud_devops from '../tribe-data/Cloud-devops.json';
import Automated_testing from '../tribe-data/Automated_testing.json';
import Microsoft from '../tribe-data/Microsoft.json';
import Data from '../tribe-data/Data.json';
import Ai from '../tribe-data/AI.json';
import Security from '../tribe-data/Security.json';

const visualize_radar = require('../assets/js/radar-0.7.2-custom.js');

@Component
export default class TechRadar extends Vue {

	@Prop() private tribeName!: TribeNames;

	private tribeData = {
		[TribeNames.ARCHITECTUUR]: Architectuur,
		[TribeNames.JAVA]: Java,
		[TribeNames.MICROSOFT]: Microsoft,
		[TribeNames.FRONT_END]: Front_end,
		[TribeNames.AUTOMATED_TESTING]: Automated_testing,
		[TribeNames.CLOUD_DEVOPS]: Cloud_devops,
		[TribeNames.MOBILE]: Mobile,
		[TribeNames.DATA]: Data,
		[TribeNames.AI]: Ai,
		[TribeNames.SECURITY]: Security
	};

	private radarData: any = {
		svg_id: this.tribeName,
			width: 1450,
			height: 1000,
			colors: {
				background: "#232323",
				grid: "#FFF",
				inactive: "#fff"
			},
			title: this.tribeName,
			quadrants: [
				{ name: Quadrants.BOTTOM_RIGHT },
				{ name: Quadrants.BOTTOM_LEFT },
				{ name: Quadrants.TOP_LEFT },
				{ name: Quadrants.TOP_RIGHT }
			],
			rings: [
				{ name: Rings.ADOPT, color: "#3FE099" },
				{ name: Rings.TRIAL, color: "#FFE000" },
				{ name: Rings.ASSESS, color: "#644AE0" },
				{ name: Rings.HOLD, color: "#E09860" }
			],
			print_layout: true,
		};

	async mounted() {

		this.radarData.entries = this.tribeData[this.tribeName];

		visualize_radar.default(this.radarData);
	}


}
